<template>
	<header v-if="!isLoginPage" class="topHead d-print-none">
		<div class="container">
			<div class="row">
				<div class="col-md-4 col-6 topHeadLeft text-center d-flex align-items-center justify-content-center py-3 px-3 px-lg-5">
					<router-link to="/" class="hvrlinkbutton"><img src="../assets/image/headLogo.png" class="img-fluid" /></router-link>
				</div>
				<div class="col-md-8 col-6">
					<div id="nav" class="topHeadNav mt-5">
						<b-navbar toggleable="lg">
							<b-navbar-toggle class="ml-auto my-1" target="nav-collapse"></b-navbar-toggle>
							<b-collapse id="nav-collapse" is-nav>
								<b-navbar-nav v-if="isLoggedIn">
									<!-- *** LOGGED IN MENU -->
									<b-nav-item to="/">Home</b-nav-item>
									<b-nav-item to="/about">About Us</b-nav-item>
									<b-nav-item to="/howitworks">How it Works</b-nav-item>
									<b-nav-item v-if="!isAdminReports" to="/myprofile">My Profile</b-nav-item>

									<b-nav-item-dropdown v-if="isAdminReports" text="Admin" right>
										<b-dropdown-item to="/myprofile">My Profile</b-dropdown-item>
										<b-dropdown-item to="/adhoc">Adhoc</b-dropdown-item>
										<b-dropdown-item to="/sendtoall">Send To All</b-dropdown-item>
										<b-dropdown-item to="/reportrecommendations">Recommendations Report</b-dropdown-item>

										<!--<b-dropdown-item to="/management statement">Management Statement</b-dropdown-item>-->
										<!--<b-dropdown-item v-if="isManager" to="/reportmanagement">Management Report</b-dropdown-item>-->
									</b-nav-item-dropdown>

									<b-nav-item-dropdown text="My Rewards" right>
										<b-dropdown-item to="/rewards">Redeem Rewards</b-dropdown-item>
										<b-dropdown-item to="/rewards/basket">My Basket</b-dropdown-item>
										<b-dropdown-item to="/orders">My Orders</b-dropdown-item>
										<b-dropdown-item to="/statement">Statement</b-dropdown-item>
									</b-nav-item-dropdown>

									<b-nav-item to="/logout">Log Out</b-nav-item>
								</b-navbar-nav>
								
								<b-navbar-nav v-else class="ml-auto">
									<!-- *** NOT LOGGED IN MENU -->
									<b-nav-item to="/">Home</b-nav-item>
									<b-nav-item to="/about">About Us</b-nav-item>
									<b-nav-item to="/howitworks">How it Works</b-nav-item>
								</b-navbar-nav>
							</b-collapse>
						</b-navbar>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isLoggedIn" style="background-color:#e30613; margin-bottom:60px">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 my-lg-2 text-left text-lg-left text-center">
						<img id="imgProfilePic" :src="profilePicSrc" class="d-none d-lg-block headprofilepic img-fluid" style="position:absolute; margin-top:30px" />
						<img id="imgProfilePic" :src="profilePicSrc" class="d-lg-none headprofilepic img-fluid" style="" />
					</div>
					<div class="col-lg-8 my-1 mt-lg-3">
						<div class="text-lg-left text-center"><span class="headHelloName" id="headHelloName">Hello {{ firstname }}</span></div>
						<div class="headPoints text-lg-left text-center" id="headHelloPointsRedeem">You have <strong>{{ awardsPointsAvailable }}</strong> Royal Rands</div>
					</div>
					<div class="col-lg-2 my-1 mt-lg-3 text-center text-lg-right d-none d-lg-block" style="border-left:solid 1px #fff">
						<router-link to="/orders" class="hvrlinkbutton"><img src="../assets/image/myOrders.png" class="img-fluid" /></router-link>
					</div>
					<div class="col-lg-2 my-1 mt-lg-3 text-center text-lg-right d-lg-none">
						<router-link to="/orders" class="hvrlinkbutton"><img src="../assets/image/myOrders.png" class="img-fluid" /></router-link>
					</div>
				</div>
			</div>
		</div>

	</header>
</template>

<script>
	//
	//import { mapState } from 'vuex'
	//import SitecodeImage from '@/components/SitecodeImage';
	export default {
		name: 'AppHeader',
		components: {
			//'sitecode-image': SitecodeImage
		},
		computed: {
			windowWidth() {
				return this.$store.state.windowWidth
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rootFolder() {
				return process.env.BASE_URL
			},
			isLoginPage() {
				return this.$route.name === "Hello";
			},
			//        imgUrl() {
			////return '../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png';
			//return require('../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png');
			//        },
			firstname() {
				return this.$store.state.memberStore.member.firstname
			},//,
			profilePicSrc() {
				return '/profilepic/' + this.$store.state.memberStore.member.picId + this.$store.state.memberStore.profilepicSfx
			},//,
			//...mapState({
			//    member: state => state.memberStore.member
			//})
			awardsPointsAvailable() {
				return this.$store.state.memberStore.member.awardsPointsAvailable
			},
			awardsPointsAvailable1() {
				return this.$store.state.memberStore.member.awardsPointsAvailable1
			},
			awardsPointsAvailableMng() {
				return this.$store.state.memberStore.member.awardsPointsAvailableMng
			},
			isAdminReports() {
				return this.$store.state.memberStore.member.isAdminReports
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		updated() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		watch: {
			windowWidth() { //newWidth, oldWidth
				//console.log(`width changed to ${newWidth} from ${oldWidth}`);
				this.handleResize();
			}
		},
		methods: {
			handleResize: function () {
				if (this.$store.state.siteTemplate != 'santam') return; //Only Santam needs sizing
				if (!this.$refs.topHeadLogoLeft) {
					console.log('handleResize: cannot find topHeadLogoLeft');
					setTimeout(this.handleResize, 250)
					return;
				}

				let h = this.$refs.topHeadLogoLeft.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
				let w = this.$refs.topHeadLogoLeft.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();

				if (w < 3 || h < 3) {
					//console.log('handleResize: topHeadLogoLeft w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
				}

				let sw = w / 354;
				let sh = h / 298;

				console.log('handleResize: topHeadLogoLeft w: ' + sw + ', h: ' + sh);
				this.$refs.imgProfilePic.style.left = (sw * 122) + "px";
				this.$refs.imgProfilePic.style.top = (sh * 68) + "px";
				this.$refs.imgProfilePic.style.width = (sw * 222) + "px";
				this.$refs.imgProfilePic.style.height = (sh * 222) + "px";
			}
		}
	}
</script>

