<template v-if="isLoggedIn">
    <div class="container page">
        <div v-if="pgMode=='view' && member" class="card card-default">
            <div class="card-header">
                <h3>My Profile</h3>
            </div>
            <div class="card-body">
                <!--<div class="row mb-2">
        <div class="col-md-3 fldName">Participating in GET TO KNOW?</div>
        <div class="col-md-9 fldValue">{{ member.interest1 ? 'Yes' : 'No' }}</div>
    </div>-->
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Profile Picture:</div>
                    <div class="col-md-9 fldValue"><img id="imgProfilePic" :src="profilePicTempSrc" class="img-fluid" /></div>
                </div>

                <!--<div class="form-group">
        <asp:Label AssociatedControlID="litEmail" runat="server">Receiving daily celebration emails?</asp:Label>
        <p class="form-control-static"><asp:Literal ID="litSendCelebrationEmail" runat="server" /></p>
    </div>
    <hr /> -->
                <!--<div class="form-group">
        <asp:Label AssociatedControlID="litEmail" runat="server">Gift Card:</asp:Label>
        <p class="form-control-static" id="divCardInfo"></p>
    </div> -->
                <hr />


                <div class="row mb-3">
                    <div class="col-md-3 fldName">Name:</div>
                    <div class="col-md-9 fldValue">{{ member.firstname + ' ' + member.surname }}</div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Okta ID:</div>
                    <div class="col-md-9 fldValue">{{ member.postAddr2 ? member.postAddr2 : 'None' }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Mobile Number:</div>
                    <div class="col-md-9 fldValue">{{ member.memberNumber }}  <!--<a href="#" @click.prevent="" class="ml-5 font-italic">Change your password</a>--> </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Email Address:</div>
                    <div class="col-md-9 fldValue">{{ member.email }}</div>
                </div>

                <!--<div class="row mb-3">
        <div class="col-md-3 fldName">Mobile Number:</div>
        <div class="col-md-9 fldValue">{{ member.cellNum }}</div>
    </div>-->

                <hr />
                <!--<div class="row mb-3">
        <div class="col-md-3 fldName">Get To Know:</div>
        <div class="col-md-9 fldValue">{{ member.interest1 ? 'Participating in GET TO KNOW' : 'Not Participating in GET TO KNOW'}}</div>
    </div>-->
                <!--<template v-if="member.interest1">-->

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Date of Birth:</div>
                    <div class="col-md-9 fldValue">{{ formatDOB }}</div>
                </div>

                <!--<div class="row mb-3">
        <div class="col-md-3 fldName">Hobbies And Interests:</div>
        <div class="col-md-9 fldValue">{{ member.interestOtherDesc }}</div>
    </div>

    <div class="row mb-3">
        <div class="col-md-3 fldName">Dreams And Aspirations:</div>
        <div class="col-md-9 fldValue">{{ member.otherInfo }}</div>
    </div>-->
                <!--</template>-->
                <content-db contentKey="btmmsgmemberdetails" :isCache="true"></content-db>
            </div>
            <div class="card-footer text-right">
                <a href="#" @click.prevent="formSetState(fPassword, true, true);pgModePassword=='view';$bvModal.show('modalPassword')" class="mr-2 btn btn-primary"><span>Change Your Password</span></a>
                <!--<a href="#" @click.prevent="pgMode='edit'" class="ml-5 btn btn-primary"><span>Update Your Details</span></a>-->

                <router-link :to="{ name: 'MyProfileUpdate', params: { urlParam: 'update' }}" class="btn btn-primary"><span>Update Your Details</span></router-link>
            </div>
        </div>

        <div v-if="pgMode=='edit'" class="card card-default">
            <b-form @submit.prevent="onMemberUpdate" novalidate>
                <div class="card-header">
                    <h3>Update Profile</h3>
                </div>
                <div class="card-body">

                    <div v-if="doForceMemberUpdate" class="alert alert-warning" role="alert" runat="server" id="divCellRequired">
                        We require you to have an email address in order to use this portal.<br />Please update your details below.
                    </div>

                    <b-form-group id="firstnameGrp" label-for="firstnameTxt" label="First Name:" :invalid-feedback="fProfile.errors.firstname">
                        <b-form-input id="firstnameTxt" v-model="fProfile.form.firstname" :state="fProfile.state.firstname" placeholder="" maxlength="80" autocomplete="given-name" />
                    </b-form-group>

                    <b-form-group id="surnameGrp" label-for="surnameTxt" label="Surname:" :invalid-feedback="fProfile.errors.surname">
                        <b-form-input id="surnameTxt" v-model="fProfile.form.surname" :state="fProfile.state.surname" placeholder="" maxlength="80" autocomplete="family-name" />
                    </b-form-group>

                    <!-- v-if="member.postAddr2==''" -->
                    <b-form-group  id="postAddr2Grp" label-for="postAddr2Txt" label="Okta Id:" :invalid-feedback="fProfile.errors.postAddr2">
                        <b-form-input id="postAddr2Txt" v-model="fProfile.form.postAddr2" :state="fProfile.state.postAddr2" placeholder="" maxlength="30" />
                        <small>If you have an Okta Id, please enter it here</small>
                    </b-form-group>
                    <!--<div class="mb-3" v-else>
                        <div class="">Okta Id:</div>
                        <div class="fldValue">{{ fProfile.form.postAddr2 }}</div>
                    </div>-->

                    <div class="mb-3">
                        <div class="">Mobile Number:</div>
                        <div class="fldValue">{{ member.memberNumber }}</div>
                    </div>

                    <!--<b-form-group id="cellNumGrp" label-for="cellNumTxt" label="Mobile Number:" :invalid-feedback="fProfile.errors.cellNum">
        <b-form-input id="cellNumTxt" v-model="fProfile.form.cellNum" :state="fProfile.state.cellNum" placeholder="" maxlength="20" />
    </b-form-group>-->
                    <b-form-group id="emailGrp" label-for="emailTxt" label="Email Address:" :invalid-feedback="fProfile.errors.email">
                        <b-form-input id="emailTxt" v-model="fProfile.form.email" :state="fProfile.state.email" placeholder="" maxlength="80" autocomplete="email" />
                    </b-form-group>

                    <div>Profile Picture:</div>
                    <div class="row mb-5">
                        <div class="col-sm-3" id="divUpdateProfilePic">
                            <img id="imgProfilePic" :src="profilePicTempSrc" class="img-fluid" />
                        </div>
                        <div class="col-sm-6">
                            <div>Submit an appropriate picture, face only.</div>
                            <div>
                                <b-form-file v-model="fileProfilePic"></b-form-file>
                            </div>
                            <div>
                                <!--<button title="Rotate Picture" type="button" class="btn btn-default btn-sm" onclick="profilePic.rotate()"><span class="glyphicon glyphicon-repeat" aria-hidden="true"></span> Rotate</button>-->
                            </div>
                        </div>
                    </div>


                    <!--<b-form-group id="interest1Group" label-for="interest1" description="" :invalid-feedback="fProfile.errors.interest1" :state="fProfile.state.interest1">
        <template slot="label">
            Do you wish to participate in
            <a href="#" @click.prevent="$bvModal.show('modalGetToKnow')">GET TO KNOW</a> ?
        </template>
        <b-form-radio-group id="interest1RadioGroup" v-model="fProfile.form.interest1" name="interest1" :state="fProfile.state.interest1">
            <b-form-radio :value="true">Yes</b-form-radio>
            <b-form-radio :value="false">No</b-form-radio>
        </b-form-radio-group>
    </b-form-group>-->

                    <b-form-group label-for="dobDD" label="Date of Birth:" description="Leave blank if you do not wish to celebrate your birthday." :invalid-feedback="fProfile.errors.firstname">
                        <div class="form-inline">
                            <b-form-select id="dobDD" class="mr-1" v-model="fProfile.form.dOBdd" :options="dateOptionsDD" autocomplete="bday-day"></b-form-select>
                            <b-form-select class="mx-1" v-model="fProfile.form.dOBmm" :options="dateOptionsMM" autocomplete="bday-month"></b-form-select>
                            <a href="#" @click.prevent="fProfile.form.dOBdd='';fProfile.form.dOBmm='';" class="ml-3">Clear DOB</a>
                        </div>
                    </b-form-group>

                    <section v-if="fProfile.form.interest1">

                        <!--<b-form-group id="interestOtherDescGrp" label-for="interestOtherDescTxt" label="Hobbies And Interests:" description="" :invalid-feedback="fProfile.errors.interestOtherDesc">
            <b-form-input id="interestOtherDescTxt" v-model="fProfile.form.interestOtherDesc" :state="fProfile.state.interestOtherDesc" placeholder="" maxlength="50" />
        </b-form-group>

        <b-form-group id="otherInfoGrp" label-for="otherInfoTxt" label="Dreams And Aspirations:" description="" :invalid-feedback="fProfile.errors.otherInfo">
            <b-form-input id="otherInfoTxt" v-model="fProfile.form.otherInfo" :state="fProfile.state.otherInfo" placeholder="" maxlength="50" />
        </b-form-group>-->
                    </section>
                    <hr />
                    <div v-if="doForceMemberUpdate">
                        <b-form-checkbox v-model="termsAccepted">
                            I accept the <a href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a> of this programme.
                        </b-form-checkbox>
                    </div>
                    <div v-else>
                        You have already accepted the <a href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a> of this programme.
                    </div>

                </div>
                <div class="card-footer">
                    

                    <div class="text-right"><router-link :to="{ name: 'MyProfile' }" class="btn"><span>Back</span></router-link> <b-button type="submit" variant="primary" class="ml-3">Submit</b-button></div>
                </div>
            </b-form>
        </div>

        <!-- *** MODAL *** -->
        <b-modal id="modalGetToKnow" scrollable ok-only size="lg" title="About Get To Know">
            <i>Content: gettoknowabout</i>
        </b-modal>

        <!-- *** MODAL *** -->
        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>

        <!-- *** MODAL *** -->
        <b-modal id="modalPassword" scrollable ok-only size="lg" title="Change Password" hide-footer no-close-on-backdrop>

            <div v-if="pgModePassword=='view'" class="card card-default">
                <b-form @submit.prevent="onPassword" novalidate>

                    <div class="card-body">
                        <!--
                        <b-form-group id="surnameGrp" label-for="surnameTxt" label="Surname:" :invalid-feedback="fProfile.errors.surname" description="Your surname must match that on our records.">
                            <b-form-input id="surnameTxt" v-model="fProfile.form.surname" :state="fProfile.state.surname" placeholder="" maxlength="80" />
                        </b-form-group>
                    -->
                        <!--Fp = Forgot Password-->



                        <b-form-group id="passwordGrp" label-for="passwordTxt" label="Password:" description="Enter a password of at least 8 characters that includes at least 1 letter and 1 number." :invalid-feedback="fPassword.errors.password">
                            <b-form-input type="password" id="passwordTxt" v-model="fPassword.form.password" :state="fPassword.state.password" placeholder="" maxlength="80" autocomplete="new-password" />
                        </b-form-group>

                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgModePassword=='cellverify'" class="card card-default">
                <b-form @submit.prevent="onPasswordOtpVerify" novalidate>
                    <div class="card-header">
                        We have sent a one time pin to {{ fPassword.form.cellNum }}. Please enter it here to confirm registration:
                    </div>
                    <div class="card-body">
                        <b-form-group id="otpGrp" label-for="otpTxt" label="One Time Pin (OTP):" :invalid-feedback="fPassword.errors.otp" description="Enter the OTP you recieve by SMS.">
                            <b-form-input id="otpTxt" v-model="fPassword.form.otp" :state="fPassword.state.otp" placeholder="" maxlength="20" autocomplete="one-time-code" />
                        </b-form-group>
                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button @click="pgModePassword='view'">Back</b-button> <b-button class="ml-3" type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgModePassword=='done'" class="card card-default">
                <div class="card-header">
                    Thank you
                </div>
                <div class="card-body">
                    You have successfully updated your password, and may now login using your new credentials.
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button @click="pgModePassword='view';$bvModal.hide('modalPassword')">Close</b-button></div>
                </div>
            </div>

        </b-modal>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
	import ContentDb from '@/components/ContentDb';

    export default {
        name: 'MyProfile',
        components: { ContentDb },
        data() {
            return {
                pgMode: 'view',
                fProfile: {
                    form: { interest1: false, firstname: '', surname: '', postAddr2:'', email: '', cellNum: '', dOBmm: '', dOBdd:'', interestOtherDesc: '', otherInfo: '' }
                },
				pgModePassword: 'view',
				fPassword: {
					form: { cellNum: '', password: '', otp: '', auth: '' }
				},

                fileProfilePic: null,
                dateOptionsMM: [],
                dateOptionsDD: [],
                picSfx: '0',
                termsAccepted: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member;
            },
            doForceMemberUpdate() {
                //return this.member.cellNum === '' || this.member.email === ''; //if either cellnum or email is blank, then we need them to force them to accept terms and conditions
                return this.member.email === '';
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn;
            },
            profilePicTempSrc() {
				return '/profilepictemp/' + this.$store.state.memberStore.member.picId + '/' + this.picSfx
            },
			formatDOB() {
				if (typeof this.member.dOBdd === 'undefined' || this.member.dOBmm === 'undefined' || this.member.dOBdd === '' || this.member.dOBmm === '') return '(Not provided)';
				let dd = parseInt(this.member.dOBdd);
				let mm = parseInt(this.member.dOBmm);
				if (isNaN(dd) || isNaN(mm) ||
					(dd < 1 || mm < 1 || mm > 12 || dd > 31) ||
					((mm == 4 || mm == 6 || mm == 9 || mm == 11) && dd > 30) ||
					((mm == 2) && dd > 29)) return '(Not provided)';
				let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				return dd + ' ' + months[mm - 1];
			}
        },
		props: {
			urlParam: { type: String, default: '' }
		},
        watch: {
            fileProfilePic: function (newFileProfilePic) {
                if (newFileProfilePic !== null) {
                    this.doFileUpload();
                }
            },
        },

		beforeRouteEnter(to, from, next) {
			next(vm => { // access to component instance via `vm` - i.e. use 'vm' instead of 'this'
				// console.log('*beforeRouteEnter');
				//console.log(to);
				//console.log(from);
				vm.doNavigation();
			});
		},

        created() {

            this.formSetState(this.fProfile, true, true) //formObj, resetState, resetForm
			this.formSetState(this.fPassword, true, true); //formObj, resetState, resetForm

            this.dateOptionsDD = [];
            this.dateOptionsMM = [];

            this.dateOptionsDD.push({ value: null, text: '' });
            this.dateOptionsMM.push({ value: null, text: '' });

            for (var d = 1; d < 32; d++) {
				this.dateOptionsDD.push({ value: d.toString(), text: ('' + d) });
            }

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            for (var m = 1; m <= 12; m++) {
				this.dateOptionsMM.push({ value: m.toString(), text: months[m - 1] });
            }

            let curYear = new Date().getFullYear();
            for (var y = 12; y < 110; y++) {
                let theyear = curYear - y;
				this.dateOptionsYY.push({ value: theyear.toString(), text: theyear.toString() });
            }

			this.doNavigation();

        },
        mounted() {
            this.doGetMember();

            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            newPicTemp() {
				this.picSfx = ((new Date).getTime() - 1); 
            },
            doNavigation() {
				const param = typeof this.$route.params.urlParam === 'undefined' ? '' : this.$route.params.urlParam;//  this.urlParam;
				console.log('doNavigation route param:');
				console.log(param);

				if (param === 'update') {
					this.pgMode = 'edit'
				} else {
					this.pgMode = 'view'
				}
            },
            doGetMember() {

                axios.post('/api/member/getmemberdata', { resetimg: true })
                    .then(({ data }) => {

                        this.formSetState(this.fProfile, true, true);//formObj, resetState, resetForm
                        let memdata = data.memberData;
                        var fields = Object.getOwnPropertyNames(this.fProfile.form);

                        for (let i = 0; i < fields.length; i++) {
                            let field = fields[i];
                            this.fProfile.form[field] = memdata[field];
                        }


                        this.newPicTemp();
                        this.doNavigation();
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving member data');
                        }
                    })
            },
            doFileUpload() {
                let formData = new FormData();

                formData.append('fileProfilePic', this.fileProfilePic, this.fileProfilePic.name);
                axios.post('/api/member/profilepicupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.newPicTemp();
                    })
                    .catch(function () {
                        alert('FAILURE UPLOAD!!');
                    });
            },

            onMemberUpdate() {
				if (this.doForceMemberUpdate && !this.termsAccepted) {
                    this.msgBox('Terms and Conditions are required to be accepted to participate in this program');
                    return;
				}

                /*
				if (!this.fProfile.form.interest1) { //Not participating so clear get to know
					this.fProfile.form.dOByy = '';
					this.fProfile.form.dOBmm = '';
					this.fProfile.form.dOBdd = '';
					this.fProfile.form.interestOtherDesc = '';
					this.fProfile.form.otherInfo = '';
				}
                */

                this.formSetState(this.fProfile, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/member/memberupdate',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.$store.dispatch('memberStore/getMemberData');
						this.$store.dispatch('memberStore/doSetProfilePicSfx');
                        this.msgBox('Thank you for updating your details.');
						this.$router.push({ name: 'MyProfile' });
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },


			onPassword() {
				this.fPassword.form.otp = '';
                this.fPassword.form.auth = '';
                this.fPassword.form.cellNum = this.fProfile.form.cellNum;
				this.formSetState(this.fPassword, true);//formObj, resetState, resetForm

				return axios
					.post(
						'/api/member/passwordsendcell2factorsms',
						this.fPassword.form
					)
					.then(({ data }) => {
						this.fPassword.form.auth = data.auth;
						console.log(data);
						this.pgModePassword = 'cellverify';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fPassword.errors = error.response.data.formErrors;
								this.formSetState(this.fPassword);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},

			onPasswordOtpVerify() {
				//if (!this.fPassword.form.otp.length != 6) {
				//                this.msgBox('Invalid OTP entered');
				//                return;
				//            }

				return axios
					.post(
						'/api/member/passwordcell2factorverify',
						this.fPassword.form
					)
					.then(({ data }) => {
						console.log(data);
						this.pgModePassword = 'done';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fPassword.errors = error.response.data.formErrors;
								this.formSetState(this.fPassword);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},

        }

    }
</script>
