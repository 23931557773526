<template>
    <section class="container page">
        <div v-if="pgMode=='step1'" class="card card-default">
            <b-form @submit.prevent="onStep1" novalidate>
                <div class="card-header">
                    <h4>Register - Step 1</h4>
                </div>
                <div class="card-body">

                    <div class="mb-3">
                        <p>This incentive programme is only for staff of Royal Canin Customers (Pet Shops/Vet Shops and Veterinary Practices).</p>
                        <p>
                            Clicking submit will confirm the mobile number we have for you on record.<br />
                            We will then send you an SMS with a one time pin (OTP) to your mobile phone to continue with registration.
                        </p>
                    </div>
                    <b-form-group class="my-4 ml-3" id="cellNumGrp" label-for="cellNumTxt" label="Mobile Number:" :invalid-feedback="fProfile.errors.cellNum" description="We will send you a one time pin (OTP) to verify your mobile number.">
                        <b-form-input id="cellNumTxt" v-model="fProfile.form.cellNum" :state="fProfile.state.cellNum" placeholder="" maxlength="20" class="col-sm-5" autocomplete="username" />
                    </b-form-group>

                    <b-form-group class="my-4 ml-3" id="cellNumGrp" label-for="postAddr2Txt" label="Okta ID:" :invalid-feedback="fProfile.errors.postAddr2" description="If you have an Okta ID, please enter it here.">
                        <b-form-input id="postAddr2Txt" v-model="fProfile.form.postAddr2" :state="fProfile.state.postAddr2" placeholder="" maxlength="20" class="col-sm-5" autocomplete="username" />
                    </b-form-group>

                    <a href="#" @click.prevent="$bvModal.show('modalTerms')">Terms and Conditions</a>
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>

        <div v-if="pgMode=='step2'" class="card card-default">
            <b-form @submit.prevent="onStep2" novalidate>
                <div class="card-header">
                    <h4>Register - Step 2</h4>
                </div>
                <div class="card-body">
                    <div class="alert alert-info" role="alert">
                        We have sent a one time pin to: <b>{{ fProfile.form.cellNum }}</b>.
                    </div>

                    <b-form-group id="otpGrp" label-for="otpTxt" label="One Time Pin (OTP):" :invalid-feedback="fProfile.errors.otp" description="Enter the OTP you recieve by SMS.">
                        <b-form-input id="otpTxt" v-model="fProfile.form.otp" :state="fProfile.state.otp" placeholder="" maxlength="20" autocomplete="one-time-code" />
                    </b-form-group>

                    <hr />
                    <div class="mb-3" v-if="fProfile.form.postAddr2">
                        <div class="">Okta Id:</div>
                        <div class="fldValue">{{ fProfile.form.postAddr2 }}</div>
                    </div>


                    <b-form-group id="firstnameGrp" label-for="firstnameTxt" label="First Name:" :invalid-feedback="fProfile.errors.firstname">
                        <b-form-input id="firstnameTxt" v-model="fProfile.form.firstname" :state="fProfile.state.firstname" placeholder="" maxlength="80" autocomplete="given-name" />
                    </b-form-group>

                    <b-form-group id="surnameGrp" label-for="surnameTxt" label="Surname:" :invalid-feedback="fProfile.errors.surname">
                        <b-form-input id="surnameTxt" v-model="fProfile.form.surname" :state="fProfile.state.surname" placeholder="" maxlength="80" autocomplete="family-name" />
                    </b-form-group>


                    <b-form-group id="emailGrp" label-for="emailTxt" label="Email Address:" :invalid-feedback="fProfile.errors.email" description="We will send order confirmations to this email address">
                        <b-form-input id="emailTxt" v-model="fProfile.form.email" :state="fProfile.state.email" placeholder="" maxlength="80" autocomplete="email" />
                    </b-form-group>

                    <b-form-group id="passwordGrp" label-for="passwordTxt" label="Password:" description="Enter a password of at least 8 characters that includes at least 1 letter and 1 number." :invalid-feedback="fProfile.errors.password">
                        <b-form-input type="password" id="passwordTxt" v-model="fProfile.form.password" :state="fProfile.state.password" placeholder="" maxlength="80" autocomplete="new-password" />
                    </b-form-group>
                    <hr />
                    <b-form-checkbox v-model="termsAccepted">
                        I accept the terms and conditions of this programme. <a href="#" @click.prevent="$bvModal.show('modalTerms')">Click here to read terms and conditions</a>
                    </b-form-checkbox>
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>


        <div v-if="pgMode=='done'" class="card card-default">
            <div class="card-header">
                Registration Complete
            </div>
            <div class="card-body">
                <p>You have successfully registered, and may now login using your credentials.</p>
                <p><router-link to="/hello" class="hvrlinkbutton mx-2">Click here to login</router-link></p>
            </div>
        </div>


        <!-- Modal  -->
        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>

    </section>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'Register',
        components: { ContentDb },
        data() {
            return {
                pgMode: 'step1',
                fProfile: {
                    form: { firstname: '', surname: '', email: '', cellNum: '', password: '', otp: '', auth: '', postAddr2: '' }
                },
                termsAccepted: false,

            }
        },
        computed: {

        },
        props: {
        },
        watch: {
            //'$route': 'doNavigation',
        },
        created() {
            this.formSetState(this.fProfile, true, true); //formObj, resetState, resetForm
        },
        mounted() {

        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            //doNavigation() {
            //    const param = this.urlParam;//this.$route.params.urlParam;//  this.urlParam;

            //    if (param === 'update') {
            //        //console.log('doNavigationBasket' + param);
            //        this.pgMode = 'edit'
            //    } else {
            //        this.pgMode = 'view'
            //    }
            //},

			onStep1() {
				this.fProfile.form.otp = '';
				this.fProfile.form.auth = '';
				this.formSetState(this.fProfile, true);//formObj, resetState, resetForm

				return axios
					.post(
						'/api/member/sendcell2factorsms',
                        { 'cellNum': this.fProfile.form.cellNum, 'postAddr2': this.fProfile.form.postAddr2  }
					)
					.then(({ data }) => {
						this.fProfile.form.auth = data.auth;
						this.pgMode = 'step2';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fProfile.errors = error.response.data.formErrors;
								this.formSetState(this.fProfile);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},


            onStep2() {
				if (!this.termsAccepted) {
					this.msgBox('Terms and Conditions are required to be accepted to participate in this program');
					return;
				}
                return axios
                    .post(
                        '/api/member/cell2factorverify',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.pgMode = 'done';
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },

        }

    }
</script>
