<template>
    <footer v-if="!isLoginPage" class="pagefooter clearfix d-print-none mt-5 py-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-8 text-center pt-2">
                    <a href="https://www.royalcanin.com/za" class="hvrlinkbutton mx-2" target="_blank">&copy; {{ (new Date()).getFullYear() }} Royal Canin</a>
                    | <a href="https://www.royalcanin.com/za" class="hvrlinkbutton mx-2" target="_blank">Royal&nbsp;Canin&nbsp;Hub</a>
                    | <router-link to="/terms" class="hvrlinkbutton mx-2">Terms&nbsp;and&nbsp;Conditions</router-link>
                    | <router-link to="/disclaimer" class="hvrlinkbutton mx-2">Disclaimer</router-link>
                    <!--| <a href="#" class="hvrlinkbutton mx-2" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Manage Privacy</a>
                    | <router-link to="/privacy" class="hvrlinkbutton mx-2">Privacy Policy</router-link>-->
                    <template v-if="isLoggedIn"> | <router-link to="/contactus" class="hvrlinkbutton mx-2">Contact Us</router-link></template>
                    
                </div>
                <div class="col-sm-1">
                    <!-- OneTrust Cookies Settings button start -->
                    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                    <!-- OneTrust Cookies Settings button end -->
                </div>
                <div class="col-sm-2 text-right">
                    <a href="https://www.therewardsfactory.co.za/" target="_blank"><img src="../assets/image/loginPoweredBy2.png" class="img-fluid ml-2" /></a>
                </div>
            </div>
        </div>

        <!--<img src="../assets/image/footer.png" class="img-fluid d-none d-lg-block" />
    <img src="../assets/image/footerMobi.png" class="img-fluid d-lg-none" />-->
    </footer>
</template>

<script>
//import { mapState } from 'vuex'
    export default {
        name: 'AppFooter',
        components: {
        },
        computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            rootFolder() {
                return process.env.BASE_URL
            },
            isLoginPage() {
                return this.$route.name === "Hello";
			}
        }
    }
</script>

