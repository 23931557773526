<template>
	<div>
		<div class="loadingOverlay" v-show="isDataLoading">Loading&#8230;</div>
		<app-header />
		<router-view />
		<app-footer />
		<b-modal id="modalPrivacy" scrollable ok-only size="lg" title="Privacy Policy">
			<content-db contentKey="privacy" :isCache="true"></content-db>
		</b-modal>
		<b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
			<content-db contentKey="terms" :isCache="true"></content-db>
		</b-modal>
		<b-modal id="modalDisclaimer" scrollable ok-only size="lg" title="Disclaimer">
			<content-db contentKey="disclaimer" :isCache="true"></content-db>
		</b-modal>
		<!--<cookie-policy />-->
	</div>
</template>

<script>
	import './assets/scss/site.scss'
	import AppHeader from '@/components/AppHeader'
	//import AppNav from '@/components/AppNav'
	import AppFooter from '@/components/AppFooter'
	import ContentDb from '@/components/ContentDb';
	//import CookiePolicy from '@/components/CookiePolicy'

	export default {
		components: { AppHeader, AppFooter, /*CookiePolicy,*/ ContentDb},
		computed: {
			isDataLoading() {
				return this.$store.state.dataLoadingCount != 0;
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			dataLoadingStyle() {
				return this.$store.state.dataLoading ? "display:block" : "display:none";
			},
			member() {
				return this.$store.state.memberStore.member;
			},
			doForceMemberUpdate() {
				let n = this.$route.name;
				if (!this.isLoggedIn || this.member.email !== '') return '';
				return (n !== 'MyProfileUpdate' && n !== 'Logout' && n !== 'LoginSuccess') ? n : ''; //if either cellnum or email is blank, then we need them to force them to accept terms and conditions
			},
			//siteTemplateComponent() {
			//    return this.$store.state.memberStore.siteTemplateComponent
			//}
		},
		watch: {
			routeName: function () {
				//console.log('routeName: ' + this.$route.name + ' ' + newVal);
				//this.isLoggedIn && this.member.cellNum === '' || this.member.email === '' && this.$route.name != 'MyProfileUpdate'
			},
			doForceMemberUpdate: function (newVal) {
				//console.log('doForceMemberUpdate: ' + this.$route.name + ' ' + newVal);
				if (newVal != '') {
					this.$router.replace({ name: 'MyProfileUpdate', params: { urlParam: 'update' } })
				}
			},
		},
		created() {
			if (this.doForceMemberUpdate != '') {
				console.log('doForceMemberUpdate*: ' + this.$route.name + ' ');
				this.$router.replace({ name: 'MyProfileUpdate', params: { urlParam: 'update' } })
			}
		},


	}
</script>

