<template>
    <section>
        <div class="hello">
            <div class="d-flex flex-column" style="min-height:100vh">
                <div class="row no-gutters flex-grow-1 d-flex">
                    <div class="col flex-grow-1 helloLogoInner">
                    </div>
                    <div class="col-md-5 col-xl-3">
                        <div class="helloLoginAreaGap d-none d-sm-block"></div>
                        <section class="helloLoginArea">
                            <div class="helloLoginAreaGap"></div>
                            <b-form class="helloForm" @submit.prevent="onLoginGo" novalidate>
                                <div class="">
                                    <input name="txtLoginMemberNumber" v-model="fLogin.form.memberNumber" class="logininput" type="text" maxlength="50" size="8" placeholder="Cellphone No" autocomplete="username" />
                                    <small>{{fLogin.errors.memberNumber}}</small>
                                </div>
                                <div class="mt-1">
                                    <input name="txtLoginPassword" type="password" v-model="fLogin.form.password" size="8" class="logininput" placeholder="Password" autocomplete="current-password" />
                                    <small>{{fLogin.errors.password}}</small>
                                </div>
                                <div class="mt-3 text-right font-italic" style="font-size:smaller"><router-link to="/forgotpassword" class="hvrlinkbutton mx-2">Forgotten your Password?</router-link></div>
                                <div>&nbsp;</div>
                                <div class="mt-5"><button type="submit" name="cmdlogin" class="btn btn-default btn-md" style="width:100%" id="cmdlogin">LOGIN</button></div>
                                <div class="mt-4 mb-5 text-center" style="font-weight:bold"><router-link to="/register" class="hvrlinkbutton mx-2">Register</router-link></div>

                                <div class="mt-5 pt-5 text-center"><a href="https://www.therewardsfactory.co.za/" target="_blank"><img src="../assets/image/loginPoweredBy.png" class="img-fluid" alt="" /></a></div>
                            </b-form>
                            <!--
                <div class="text-center">&copy; <b>Royal Canin</b> </div>
                <div class="text-center"><span id="spanFooterTerms" runat="server"><a href="/terms">Terms and Conditions</a></span></div>
                -->
                        </section>
                    </div>
                </div>
                <div class="helloFooter">
                    <a href="https://www.royalcanin.com/za" class="hvrlinkbutton mx-2" target="_blank">&copy; {{ (new Date()).getFullYear() }} Royal Canin</a> |
                    <router-link to="/about" class="hvrlinkbutton mx-2">About&nbsp;Us</router-link> |
                    <a href="https://www.royalcanin.com/za" class="hvrlinkbutton mx-2" target="_blank">Royal&nbsp;Canin&nbsp;Hub</a> |
                    <router-link to="/terms" class="hvrlinkbutton mx-2">Terms&nbsp;and&nbsp;Conditions</router-link> |
                    <router-link to="/disclaimer" class="hvrlinkbutton mx-2">Disclaimer</router-link>
                    <!--<a href="#" class="hvrlinkbutton mx-2" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Manage Privacy</a>--> |
                    <!-- <router-link to="/privacy" class="hvrlinkbutton mx-2">Privacy Policy</router-link>-->
                    <!-- OneTrust Cookies Settings button start -->
                    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                    <!-- OneTrust Cookies Settings button end -->
                </div>
            </div>
        </div>

        <!-- Modal  -->
        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>
    </section>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'Hello',
        components: { ContentDb },
        data() {
            return {
                pgMode: 'view',
                fLogin: {
                    form: { memberNumber: '', password: '' }
                },
            }
        },
        computed: {

        },
        props: {
        },
        watch: {
            //'$route': 'doNavigation',
        },
        created() {
            this.formSetState(this.fLogin, true, true); //formObj, resetState, resetForm
        },
        mounted() {
        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            //doNavigation() {
            //    const param = this.urlParam;//this.$route.params.urlParam;//  this.urlParam;

            //    if (param === 'update') {
            //        //console.log('doNavigationBasket' + param);
            //        this.pgMode = 'edit'
            //    } else {
            //        this.pgMode = 'view'
            //    }
            //},

			onLoginGo() {
				//if (!this.fProfile.form.otp.length != 6) {
				//                this.msgBox('Invalid OTP entered');
				//                return;
				//            }

				return axios
					.post(
						'/api/member/login',
						this.fLogin.form
					)
					.then(({ data }) => {
						//console.log(data);

						this.$store
							.dispatch('memberStore/loggedIn', data)
							.then(() => {
								this.$router.replace({ name: 'Home' }) //we use replace instead of push - to replace the current history entry
							})
							.catch(err => {
								let msg = err.message;
								this.msgBox('Error occurred in calling [memberStore/loggedIn]: ' + msg);
							})
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fLogin.errors = error.response.data.formErrors;
								this.formSetState(this.fProfile);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
            },
        }

    }
</script>
